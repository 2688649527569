<template>
  <div class="login">
    <div class="main">
      <div class="login_text">
        <h3>Sangfor Knowledge Base</h3>
        <p>This Knowledge Base is a self-service online library where you can find the most recent information you may need to help you better understand Sangfor products & solutions.</p>
      </div>
      <div class="login_Card">
        <div class="card_item">
          <div class="card_menu">
            <el-menu
              :default-active="activeIndex"
              class="el-menu-demo"
              mode="horizontal"
              @select="handleSelect"
            >
              <el-menu-item index="1">Partner Portal</el-menu-item>
              <el-menu-item index="2">GSM (OTP)</el-menu-item>
              <el-menu-item index="3">GSM</el-menu-item>
            </el-menu>
          </div>
          <div class="card_main">
            <partner-portal v-if="activeIndex == '1'"></partner-portal>
            <gsmotp v-else-if="activeIndex == '2'"></gsmotp>
            <gsm v-else></gsm>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Gsm from '../../components/gsm.vue';
import Gsmotp from '../../components/gsmotp.vue';
import partnerPortal from "../../components/partnerPortal.vue";
export default {
  components: { partnerPortal, Gsmotp, Gsm },
  name: "login",
  data() {
    return {
      activeIndex: "1",
    };
  },
  methods: {
    handleSelect(val) {
      this.activeIndex=val;
    },
  },
  created(){
    // console.log(this.$router);
  }
};
</script>

<style scoped lang='scss'>
.login {
  width: 100%;
  .main {
    width: 100%;
    height: calc(100vh - 90px);
    display: flex;
    background: url("../../assets/img/loginBackground.png") no-repeat;
    background-size: cover;
    background-position: center 0;
    .login_text {
      width: 63%;
      padding: 240px 210px 0 290px;
      h3 {
        width: 100%;
        font-size: 48px;
        font-weight: bold;
        color: #fff;
        text-align: center;
      }
      p {
        width: 100%;
        margin-top: 43px;
        font-size: 18px;
        color: #fff;
        text-align: left;
      }
    }
    .login_Card {
      width: 37%;
      padding: 110px 180px 0 0;
      .card_item {
        width: 100%;
        background: #fff;
        min-height: 600px;
        border-radius: 8px;
        padding: 10px 30px;
        .card_menu {
          width: 100%;
          .el-menu-demo {
            border-radius: 8px 8px 0 0;
          }
          .el-menu-demo /deep/ .el-menu-item {
            width: 33.3%;
            text-align: center;
            font-size: 18px;
            font-weight: 500;
          }
          /deep/ .el-menu--horizontal > .el-menu-item.is-active {
            color: #0d39a8;
            border-bottom: 2px solid #0d39a8;
          }
        }
        .card_main {
          padding: 20px 0;
        }
      }
    }
  }
}
</style>