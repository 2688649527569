<template>
  <div class="gsm">
    <el-form
      :model="loginForm"
      :rules="loginRules"
      ref="loginForm"
      class="demo-ruleForm"
    >
      <el-form-item prop="username" label="Employee ID:">
        <el-input
          ref="name"
          v-model="loginForm.username"
          auto-complete="on"
          name="username"
          type="text"
          placeholder="Please enter employee ID"
          @keyup.enter.native="handleLogin"
        >
          <i slot="prefix" class="el-input__icon el-icon-user"></i>
        </el-input>
      </el-form-item>
      <el-form-item prop="password" label="password:">
        <el-input
          v-model="loginForm.password"
          type="password"
          name="password"
          auto-complete="new-password"
          placeholder="Please enter password"
          @keyup.enter.native="handleLogin"
        >
          <i slot="prefix" class="el-input__icon el-icon-mobile-phone"></i>
        </el-input>
      </el-form-item>
      <el-form-item
        prop="validCode"
        label="ValidCode:"
        v-show="showVerificationCode"
      >
        <el-input
          v-model="loginForm.validCode"
          type="text"
          name="validCode"
          placeholder="Please enter validCode"
          @keyup.enter.native="handleLogin"
        >
          <i slot="prefix" class="el-input__icon el-icon-lock"></i>
          <template slot="append"
            ><img
              class="validImg"
              :src="validImgUrl"
              width="100"
              height="39"
              @click="getValidImg"
          /></template>
        </el-input>
      </el-form-item>
      <el-checkbox
        v-model="readPrivacyPolicy"
        style="margin-bottom: 24px"
        class="checkbox"
        >I have read and agree to the</el-checkbox
      >
      <a style="color: #3e84e9" @click.stop="showPrivacyPolicy = true"
        >Privacy Policy</a
      >
      <el-form-item>
        <el-button
          :loading="loading"
          type="primary"
          :disabled="!readPrivacyPolicy"
          @click.native.prevent="handleLogin"
          >Sign in</el-button
        >
      </el-form-item>
    </el-form>
    <el-dialog
      title="Privacy Policy"
      :visible.sync="showPrivacyPolicy"
      width="1200px"
      top="50px"
    >
      <div>
        <p style="color:#666666; word-break: break-word; font-size:15px; line-height:26px;">SANGFOR attach great importance to the security of your Personal Data（as defined hereinafter）seriously. Collecting, using, storing of your personal data may be conducted upon you visit this https://knowledgebase.sangfor.com  (<span style="font-size:11pt; font-weight: bold; color: rgb(102, 102, 102);">“Sangfor Knowledge Base”</span>) based on your consent, contract relationship or any applicable legal ground. This Privacy Policy represents how we collect, use, store your Personal Data as well as your legitimate rights to access, update, delete, and protect your Personal Data in relation to this Sangfor Knowledge Base.</p><br/>
        <p style="color:#666666; word-break: break-word; font-size:15px; line-height:26px;">Considering Sangfor provides various kinds of products and services, this Privacy Policy may not cover all possible scenarios for personal data processing. Any specific data relating to the processing of personal data in the context of Sangfor’s products or services may be described in the privacy policy of related products or services, or may be released in any notice that is given during the collection of data.</p><br/>
        <p style="color:#666666; word-break: break-word; font-weight: bold; line-height:26px; font-size: 11pt;">YOU ARE REQUIRED TO GET FULLY UNDERSTANDING OF THIS PRIVACY POLICY BEFORE SUBMITTING YOUR PERSONAL DATA TO SANGFOR. BY USING THIS SANGFOR KNOWLEDGE BASE, YOU ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTOOD AND AGREE TO ALL OF THE TERMS OF THIS PRIVACY POLICY.</p><br>
        <p style="color:#666666; word-break: break-word; font-size:15px; line-height:26px;">We use the following definitions in this Privacy Policy:</p>
        <p style="color:#666666; word-break: break-word; line-height:26px; font-size: 11pt;"><span style="font-weight: bold;">· “Sangfor”, “we”, “us”</span> means Sangfor Technologies Inc. and/or its affiliates. </p>
        <p style="color:#666666; word-break: break-word; line-height:26px; font-size: 11pt;"><span style="font-weight: bold;">· “Personal Data”</span> means any information relating to an identified or identifiable natural person (“data subject”); an identifiable natural person is one who can be identified, directly or indirectly, in particular by reference to an identifier such as a name, an identification number, location data, an online identifier or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity of that natural person.</p>
        <p style="color:#666666; word-break: break-word; line-height:26px; font-size: 11pt;"><span style="font-weight: bold;">· “you”</span> means the users of this Sangfor Knowledge Base.</p>
        <p style="color:#666666; word-break: break-word; line-height:26px; font-size: 11pt;"><span style="font-weight: bold;">· “Sangfor Knowledge Base”</span> means a self-service online library where you can find the most recent information you may need to help you better understand Sangfor products & solutions.</p><br>
        <p style="color:#666666; word-break: break-word; line-height:26px; font-size: 11pt; font-weight: bold;">1. Collection and Use of Personal Data</p>
        <p style="color:#666666; word-break: break-word; line-height:26px; font-size: 11pt;">Your Personal Data maybe be collected by your behavior of using this Sangfor Knowledge Base, registering relevant accounts or interacting with us. We may collect the following personal data about you:</p>
        <p style="color:#666666; word-break: break-word; line-height:26px; font-size: 11pt; font-weight: bold;">(1) Information provided by you to Sangfor</p>
        <p style="color:#666666; word-break: break-word; line-height:26px; font-size: 11pt;">This includes information about you that you provide to us. By using this Sangfor Knowledge Base, you need to log in to enjoy certain functions or services. When you log in this Sangfor Knowledge Base, we will ask you to provide the corresponding personal information, including: </p>
        <p style="color:#666666; word-break: break-word; line-height:26px; font-size: 11pt;">· your e-mail address,employee ID, password, your first name, last name, mobile /fix-line telephone number, user login account name, password, address details, company name, country, etc.</p>
        <p style="color:#666666; word-break: break-word; line-height:26px; font-size: 11pt;">· any information that you choose to share on this Sangfor Knowledge Base or when you contact us which may be considered Personal Data.</p>
        <p style="color:#666666; word-break: break-word; line-height:26px; font-size: 11pt;">We use your above Personal Data for the purposes of completing your login of Sangfor Knowledge Base, and providing corresponding service for you, including:</p>
        <p style="color:#666666; word-break: break-word; line-height:26px; font-size: 11pt;">· Providing search knowledge service.</p>
        <p style="color:#666666; word-break: break-word; line-height:26px; font-size: 11pt;">· Other service to you.</p>
        <p style="color:#666666; word-break: break-word; line-height:26px; font-size: 11pt; font-weight: bold;">(2) Information that we collect or generate about you.</p>
        <p style="color:#666666; word-break: break-word; line-height:26px; font-size: 11pt;">By using cookie and other similar technologies, this Sangfor Knowledge Base may indirectly record your personal data. When you use this Sangfor Knowledge Base, we may collect certain data automatically from your computers or devices (including mobile devices). The data we collect automatically may include:</p>
        <p style="color:#666666; word-break: break-word; line-height:26px; font-size: 11pt;">· your IP address, device type, operating system, unique device identifiers, browser-type, browser language, geographic location and other technical information.</p>
        <p style="color:#666666; word-break: break-word; line-height:26px; font-size: 11pt;">· the pages viewed, the links clicked, the amount of time spent on particular pages, mouse hovers, the date and time of the interaction, error logs, referring and exit pages and URLs, and similar information.</p>
        <p style="color:#666666; word-break: break-word; line-height:26px; font-size: 11pt;">We use this information only for our analytical purposes, and to improve the quality, relevance, and security of Sangfor Knowledge Base.</p><br>
        <p style="color:#666666; word-break: break-word; line-height:26px; font-size: 11pt;">Should the purposes of using your personal data are not included in this Privacy Policy, we will obtain your prior consent before we implementing the relevant purpose. Moreover, you are entitled to withdraw your consent at any time in compliance with the laws and regulations, but this maybe cause the function limitation of this Sangfor Knowledge Base. </p><br>
        <p style="color:#666666; word-break: break-word; line-height:26px; font-size: 11pt; font-weight: bold;">2. Cookies and Other Similar Technologies</p>
        <p style="color:#666666; word-break: break-word; line-height:26px; font-size: 11pt;">Like most sites, we use technologies that are essentially small data files placed on your computer, tablet, mobile phone, or other devices (referred to collectively as a "device") that allow us to record certain pieces of data whenever you visit or interact with this Sangfor Knowledge Base, and to recognize you across devices. </p><br>
        <p style="color:#666666; word-break: break-word; line-height:26px; font-size: 11pt;">The specific names and types of the cookies, unique identifiers, and similar technologies we use to collect data (e.g. about the pages you view, the links you click, and other actions you take on in this Sangfor Knowledge Base) may change from time to time. In order to help you better understand this Privacy Policy and our use of such technologies we have provided the following limited terminology and definitions: </p><br>
        <p style="color:#666666; word-break: break-word; line-height:26px; font-size: 11pt;">Cookies – Small text files (typically made up of letters and numbers) placed in the memory of your browser or device when you visit a website or view a message. Cookies allow a website to recognize a particular device or browser. </p><br>
        <p style="color:#666666; word-break: break-word; line-height:26px; font-size: 11pt;">Similar technologies – Technologies that store personal data in your browser or device utilizing local shared objects or local storage, such as flash cookies, HTML 5 cookies, and other web application software methods. These technologies can operate across all of your browsers, and in some instances may not be fully managed by your browser and may require management directly through your installed applications or device. We do not use these technologies for storing personal data to target advertising to you on or off our sites.</p><br>
        <p style="color:#666666; word-break: break-word; line-height:26px; font-size: 11pt;">We may use the terms "cookies" or "similar technologies" interchangeably in our policies to refer to all technologies that we may use to store data in your browser or device or that collect data or help us identify you in the manner described above.</p><br>
        <p style="color:#666666; word-break: break-word; line-height:26px; font-size: 11pt; font-weight: bold;">3. Rights to Your Personal Data</p>
        <p style="color:#666666; word-break: break-word; line-height:26px; font-size: 11pt;">If you submit your Personal Data to us, please ensure the accuracy of such data. If your Personal Data has changed (for instance, your mobile phone number has been changed), you are obliged to update your personal data in a timely manner.</p><br>
        <p style="color:#666666; word-break: break-word; line-height:26px; font-size: 11pt;">For requirements by applicable laws, you may: (1) have access to your Personal Data we have held; (2) update or correct any of your inaccurate Personal Data; (3) restrict or object to allow us to process your Personal Data; (4) require us to erasure your Personal Data;(5) require us to comply with your request for data portability; (6) withdraw your consent at any time which will not affect the lawfulness of processing based on consent before ;(7) lodge a complaint with the corresponding supervisory authority.</p><br>
        <p style="color:#666666; word-break: break-word; line-height:26px; font-size: 11pt;">When you claim the said rights, we may require you to submit a written request and may verify your identify. Usually, we do not charge anything for such processes unless your request goes beyond the limit of conventional requirements.</p><br>
        <p style="color:#666666; word-break: break-word; line-height:26px; font-size: 11pt; font-weight: bold;">4.  Protection and Storage of Personal Data</p>
        <p style="color:#666666; word-break: break-word; line-height:26px; font-size: 11pt;">We recognize the need to ensure that personal information gathered via this Sangfor Knowledge Base remains secure. Sangfor Knowledge Base has security measures in place to protect against the loss, misuse, and alteration of the personal information under our control. Our security measures include the use of a hardware firewall to prevent unauthorized access, access control, allocate system privileges, data transmission and storage encryption, data backup on EU cloud server. You acknowledge that although we exercise adequate care and security, there remains a risk that information transmitted over the Internet and stored by computer may be intercepted or accessed by an unauthorized third-party. We will retain your information for the period necessary to fulfill the purposes outlined in this Privacy Policy unless a longer retention period is required or permitted by law.</p><br>
        <p style="color:#666666; word-break: break-word; line-height:26px; font-size: 11pt; font-weight: bold;">5.  Breach Notification</p>
        <p style="color:#666666; word-break: break-word; line-height:26px; font-size: 11pt;">In line with the GDPR, where a breach is known to have occurred which is likely to result in a risk to the rights and freedoms of individuals, the relevant Data Protection Authority (DPA) will be informed within 72 hours, unless the personal data breach is unlikely to result in a risk to the rights and freedoms of natural persons. Where the notification to the supervisory authority is not made within 72 hours, Sangfor inform the supervisory authority of the reasons for the delay. This will be managed in accordance with our Information Security Incident Response Procedure which sets out the overall process of handling information security incidents.</p><br>
        <p style="color:#666666; word-break: break-word; line-height:26px; font-size: 11pt; font-weight: bold;">6.  Update of this Privacy Policy</p>
        <p style="color:#666666; word-break: break-word; line-height:26px; font-size: 11pt;">This Privacy Policy may be modified from time to time in line with legislation or industry developments. We therefore advise you to check this Privacy Policy regularly. Any material changes will be notified via this Sangfor Knowledge Base, email or other means we deem appropriate.</p><br>
        <p style="color:#666666; word-break: break-word; line-height:26px; font-size: 11pt; font-weight: bold;">7.  Contact Us</p>
        <p style="color:#666666; word-break: break-word; line-height:26px; font-size: 11pt;">If you have any further question about this Privacy Policy, or have any request or query for your Personal Data, you can always contact us by e-mail: marketing@sangfor.com.</p><br>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { encrypt, emailEncrypt } from "./../utils/secureUtil";
import { loginGsm, getGsmInfo } from "./../api/login";
export default {
  name: "gsm",
  inject:['reload'],
  data() {
    const validateUsername = (rule, value, callback) => {
      if (value.length == 0) {
        callback(new Error("请输入账号"));
      } else {
        callback();
      }
    };
    const validatePass = (rule, value, callback) => {
      if (value.length < 5) {
        callback(new Error("密码不能小于5位"));
      } else {
        callback();
      }
    };
    return {
      loginForm: {
        username: "",
        code: "",
        password: "",
        deviceId: "",
        validCode: "",
      },
      loginRules: {
        username: [{ required: true, trigger: "change" }],
        code: [{ required: true, trigger: "change" }],
        password: [{ required: true, trigger: "change" }],
        validCode: [{ required: false, trigger: "change" }],
      },
      readPrivacyPolicy: false,
      showPrivacyPolicy: false,
      validImgUrl: "",
      showVerificationCode: false,
      loading: false,
    };
  },
  methods: {
    getValidImg() {
      localStorage.setItem("loginFailTime", 2);
      let num = "";
      for (let i = 0; i < 4; i++) {
        num += parseInt(Math.random() * 10);
      }
      this.loginForm.deviceId = num;
      this.validImgUrl =
        process.env.VUE_APP_BASE_URL + "api-auth/validata/code/" + num;
      console.log(this.validImgUrl);
    },
    showOrHideValidImg() {
      let time = localStorage.getItem("loginFailTime") || 0;
      console.log(time);
      if (time > 1) {
        this.getValidImg();
        this.showVerificationCode = true;
        this.loginRules.validCode[0].required = true;
      }
    },
    handleLogin() {
      if (!this.readPrivacyPolicy) return;
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          let field = {};
          field.loginCode = encrypt({
            username: this.loginForm.username,
            password: this.loginForm.password,
          });
          if (this.showVerificationCode) {
            field.deviceId = this.loginForm.deviceId;
            field.validCode = this.loginForm.validCode;
          }
          loginGsm(field)
            .then((res) => {
              this.loading = false;
              localStorage.setItem("library_token", res.data.access_token);
              localStorage.setItem("loginFailTime", 0);
              localStorage.setItem("readPrivacyPolicy", 0);
              console.log(res);
              getGsmInfo().then((res) => {
                localStorage.setItem(
                  "library_userInfo",
                  JSON.stringify(res.data.user)
                );
                this.$store.commit("SET_USERINFO", res.data.user);
                localStorage.setItem('library_userType','gsm')
                if (this.$route.params.url) {
                  // this.reload();
                  location.href =this.$route.params.url
                } else {
                  let url = "http://" + location.host + "/";
                  location.href = url;
                }
              });
            })
            .catch((res) => {
              this.loading = false;
              let time = localStorage.getItem("loginFailTime") || 0;
              localStorage.setItem("loginFailTime", Number(time) + 1);
              if (
                time > 0 ||
                (res.response.data && res.response.data.needValidateCode)
              ) {
                this.getValidImg();
                this.showVerificationCode = true;
                this.loginRules.validCode[0].required = true;
              }
            });
        } else {
          return false;
        }
      });
    },
  },
  mounted() {
    this.showOrHideValidImg();
    if (localStorage.getItem("readPrivacyPolicy")) {
      this.readPrivacyPolicy = true;
    }
  },
};
</script>

<style scoped lang='scss'>
.gsm {
  width: 100%;
  /deep/ .el-input-group__append,
  .el-input-group__prepend {
    border: 0;
    padding: 0;
  }
  /deep/ .el-input__inner {
    border: 0;
    border-bottom: 1px solid #d2d2d2;
    border-radius: 0;
  }
  /deep/ .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #2d2f36;
    margin-right: 5px;
  }
  /deep/ .el-checkbox__label{
    color: #2D2F36;
    margin-right: 5px;
  }
}
</style>