import CryptoJS from "./crypto-js";

export function encrypt(params) {
  let AES_KEY = "eaA8eBa7EfeMfcfZ";
  let IV = "TRYTOCN394402133";
  var key = CryptoJS.enc.Utf8.parse(AES_KEY);
  var iv = CryptoJS.enc.Utf8.parse(IV);
  var jsonData = JSON.stringify(params);
  //下面的data参数要求是一个字符串，第一次用的时候我直接传递的是一个对象，出现了错误，要转换成字符串
  var data = CryptoJS.enc.Utf8.parse(jsonData);
  var encrypted = CryptoJS.AES.encrypt(data, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  }).toString();
  return encrypted;
}

export function emailEncrypt(params) {
  let AES_KEY = "1DB8eBb7FfeMfdfA";
  let IV = "TGYTRCN394552143";
  var key = CryptoJS.enc.Utf8.parse(AES_KEY);
  var iv = CryptoJS.enc.Utf8.parse(IV);
  var jsonData = JSON.stringify(params);
  //下面的data参数要求是一个字符串，第一次用的时候我直接传递的是一个对象，出现了错误，要转换成字符串
  var data = CryptoJS.enc.Utf8.parse(jsonData);
  var encrypted = CryptoJS.AES.encrypt(data, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  }).toString();
  return encrypted;
}
